.usedcars_container {
	padding: 10px;
	/* margin: 10px; */
	background: #fff;
}
.buy_sell_btn_container {
	border-radius: 30px;
	background-color: darkblue;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 5px;
	width: 150px;
	display: flex;
	justify-content: space-between;
	position: sticky;
	border: 4px solid #d8d7d5;
}
.enquirybtn {
	/* background-image: linear-gradient(to top, #09203f 0%, #537895 100%); */
	text-transform: none !important;
	border-radius: 2px !important;
	width: 200px;
	text-align: center;
	height: 44px;
	font-weight: 600;
	font-size: 12px;
	line-height: 0.01em;
	border-radius: 12px !important ;
	box-shadow: none !important;
	margin-top: 16px;
	margin-bottom: 16px;
	background: #0b1c48;
}
@media screen and (max-width: 600px) {
	.buy_sell_btn_container {
		border: 3px solid #d8d7d5;
		border-radius: 30px;
		background-color: darkblue;
		box-shadow: 2px 2.5px 2px rgba(0, 0, 0, 0.2);
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 5px;
		width: 150px;
		display: flex;
		justify-content: space-between;
		position: sticky;
	}
}
.buy_steps .css-1xzal7s-MuiGrid-root > .MuiGrid-item {
	padding: 30px !important;
}

@media only screen and (min-width: 768px) {
	.buy_true_img {
		display: none;
	}
}

@media only screen and (min-width: 992px) {
	.buy_true_img {
		display: none;
	}
}

.usedCar_sub {
	display: flex;
	flex-direction: row;
	justify-content: end;
	position: sticky;
	padding-top: 5px;
	z-index: 5;
}

.find_car_card .css-d71l8i-MuiTypography-root {
	margin-bottom: 0px !important;
}

.bottom_cards {
	border-radius: 16px;
	cursor: pointer;
	border: 2px solid #eee;
}
