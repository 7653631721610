.Onroadprice_image {
  background-position: 100%;
  background-position-x: center;
  background-repeat: no-repeat;
  align-items: center;
  background-color: #cbcbcb;
  background-size: cover;
  display: flex;
  /* margin: 20px 1%; */
  /* max-width: 1000px; */
  width: 100%;
}
.Onroadprice_main {
  display: flex;
  justify-content: center;
  /* border: 1px solid #eee; */
  padding: 30px 0px;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  align-items: center;
  /* margin-top: 10px;
	background-color: #fff; */
}
.Onroadprice_form {
  /* box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.2); */
  padding: 20px;
  /* opacity: 0.7; */
  border-radius: 10px;
  /* border-radius: 5px; */
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 350px;
  display: flex;
  height: auto;
  flex-direction: column;
  /* position: absolute; */
  /* top: 20px;
	left: 20px; */
  margin: 5% 5% 5% 150px;
}
.css-xmq9qu-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 5px !important;
}

@media (max-width: 500px) {
  .Onroadprice_image {
    padding: 0px;
  }
  .Onroadprice_form {
    margin: 5%;
  }
}
