.related_scroll_service {
	overflow-x: scroll !important ;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	width: 100%;
	cursor: pointer;
	margin-top: 10px;
}

:is(.related_scroll_service)::-webkit-scrollbar {
	display: none;
}
.related_scroll_service {
	scrollbar-width: none;
}

.realted_card_service {
	background-color: #fff;
	border-radius: 10px;
	margin: 15px;
	width: 300px;
	height: auto;
	padding: 10px;
	color: '#002441';
	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */
}
.related_img_service {
	display: flex;
	justify-content: center;
	align-items: center;
}

.related_img_service img {
	height: 100px;
	width: 100px;
}
.services_details .MuiCard-root {
	box-shadow: none !important;
}

.servicedetail_container {
	display: flex;
	background: #fafafa;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.serivce_wishlist_button {
	text-align: center;
	height: 44px;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.01em;
	border-radius: 12px !important;
}
