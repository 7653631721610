.service_banner_slider {
	width: 100%;
	display: inline-block;
	height: 35vw;
}
.services {
	background: #fff;
}
.service_banner {
	position: absolute;
	width: calc(100vw - 8px);
}
/* .service_banner_img {
} */
.services
	.title_scroll
	.MuiGrid-root.MuiGrid-container.css-14c7na6-MuiGrid-root,
.services
	.title_scroll_loader
	.MuiGrid-root.MuiGrid-container.css-14c7na6-MuiGrid-root {
	flex-wrap: nowrap !important;
}
.services .MuiCardContent-root {
	padding-bottom: 0px !important;
}
.servi_card_cecontent .servicecontent {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
}

.title_scroll .MuiGrid-root.MuiGrid-container.css-bz8pxy,
.title_scroll_loader .MuiGrid-root.MuiGrid-container.css-bz8pxy {
	flex-wrap: nowrap;
}
.title_scroll::-webkit-scrollbar,
.title_scroll_loader::-webkit-scrollbar {
	display: none !important;
}

.title_scroll {
	overflow-x: scroll;
	overflow-y: hidden;
	padding: 15px;
	scrollbar-width: none;
	background: #f1f3f4;
	border-bottom: 1px solid rgba(221, 229, 235, 0.5);
}

.title_scroll_loader {
	overflow-x: scroll;
	overflow-y: hidden;
	padding: 0 15px;
	scrollbar-width: none;
	background: #f1f3f4;
	border-bottom: 1px solid rgba(221, 229, 235, 0.5);
}

@media screen and (max-width: 767px) {
	.services .MuiBox-root.css-18ulwjz {
		width: 100% !important;
	}
	.servi_card_cecontent .servicecontent {
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
	}
}

.service_card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	justify-items: center;
	border-radius: 25px !important;
	cursor: pointer;
	border: 0.1px solid #eee;
	box-shadow: none !important;
}

.knowmorebtn {
	font-weight: 600;
	font-size: 12px;
	text-align: start;
	color: #eb232a;
	line-height: 22px;
	margin-top: 8px;
	transform: none;
}
