.css-1d3z3hw-MuiOutlinedInput-notchedOutline :hover {
  border: none !important;
  outline: none !important;
}
.filterCars {
  padding: 30px 0px;
}

@media screen and (max-width: 767px) {
  .css-15rl9pp-MuiGrid-root {
    margin-top: 0px !important;
  }
}
