.ebook {
	background-color: #eee;
	display: flex;
	width: 100%;
	height: 100vh;
}
.ebookContainer {
	background-color: #fff;
	width: 90%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 5px;
}
.tab_container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.ebook_submit {
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	border-radius: 2px !important;
	width: 250px;
}
.ebook_address_btn {
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	border-radius: 2px !important;
}
.ebook_btn {
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 10px;
}

.css-mhc70k-MuiGrid-root {
	justify-content: center;
	align-items: center;
}
.numberCount {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	width: 35px;
	height: 35px;
	text-align: center;
}
.e-book_personal_details .css-isbt42 {
	justify-content: center !important;
}
.disclaimer {
	width: 80%;
	margin: auto;
}
.css-85h2bp-MuiButtonBase-root-MuiButton-root {
	background-color: gray !important;
}
.paymentlist {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;

	/* margin: 20px 40px; */
}
.payment_btn {
	background: linear-gradient(to right, #ff416c, #ff4b2b) !important;
	width: 100px !important;
	height: 35px !important;
}
.price_btn {
	background-color: #708090 !important;
	width: 100px !important;
	height: 35px !important;
}
.otherdetails_container,
.ebook_personal_Details {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: scroll;
	height: 400px;
}
.otherdetails_container::-webkit-scrollbar,
.ebook_personal_Details::-webkit-scrollbar {
	display: none;
}
.css-k4qjio-MuiFormHelperText-root.Mui-error,
.css-1wc848c-MuiFormHelperText-root.Mui-error {
	color: #d32f2f;
	text-align: end;
	margin: 0px;
}

.addressList_modal {
	align-items: center;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}
.addressList {
	background-color: #fff !important;
	box-shadow: 0 2px 3px #aaa;
	margin: 5%;
	height: autol;
	width: 550px;
	padding: 15px;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	overflow-y: scroll;
	cursor: pointer;
}

.addressList::-webkit-scrollbar {
	display: none;
}

.list {
	background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
	text-transform: none !important;
	border-radius: 5px !important;
	width: 420px;
	padding: 15px;
	color: #fff;
	text-align: start;
}

@media screen and (max-width: 677px) {
	.ebookContainer {
		width: 100%;
	}
}

@media screen and (max-width: 900px) {
	.ebook {
		background-color: #eee;
		display: flex;
		width: 100%;
		height: 100%;
	}
}
@media screen and (max-width: 500px) {
	.list {
		width: 300px;
	}
}

.addressList_service {
	background-color: #fff !important;
	box-shadow: 0 2px 3px #aaa;
	margin: 5%;
	width: 550px;
	padding: 15px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow-y: scroll;
	cursor: pointer;
	justify-items: center;
}

.list_service.css-1p823my-MuiListItem-root {
	justify-content: center;
}

.addressList_service::-webkit-scrollbar {
	display: none;
}

.list_service {
	background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
	text-transform: none !important;
	border-radius: 5px !important;
	width: 420px;
	padding: 15px;
	color: #fff;
	text-align: start;
}

@media screen and (max-width: 500px) {
	.list_service {
		width: 300px;
	}
}
