.card_img {
  height: auto;
  width: 100%;
}
.main_card .css-8wtomc-MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}
@media screen and (max-width: 1200px) {
  .card_btn {
    background-color: "red" !important;
  }
}
@media screen and (max-width: 700px) {
  .css-qfd1gc-MuiGrid-root {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  /* .card_img{
		height: 130px;
    width: 195px;
	} */
}
@media screen and (min-width: 300px) and (max-width: 400) {
  .css-vvjqrf-MuiButtonBase-root-MuiButton-root {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* show road price modal styling */

.onroad_price {
  background-color: #fff !important;
  border-radius: 2px;
  box-shadow: 0 2px 3px #aaa;
  margin: 5%;
  width: 350px;
  padding: 15px;
}

.modal_onroad {
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  text-transform: none !important;
  border-radius: 2px !important;
}

.knowmorebtn.css-155ltdd-MuiButtonBase-root-MuiButton-root {
  padding: 0;
}
