/* .saving_corner_profile {
  background: radial-gradient(657px 657px at 2% 90%, #92b6fc 0, #dcf1f600 40%),
    radial-gradient(642px 642px at 82% 115%, #b4cdff 0, #dcf1f600 25%),
    linear-gradient(-73.25deg, #dcf2f7 7.43%, #9accd5 94.82%);
  color: #000;
} */
.savings {
	margin: 10px;
	position: relative;
	background-color: #fafafa;
}
.saving_corner {
	display: flex;
	flex-direction: column;
	/* flex: 0 0 auto; */
	width: auto;
	/* background-color: #f0f0f0; */
	padding: 10px;
	/* position: fixed; */
	/* overflow-y: auto; */
	/* align-items: stretch; */
	/* border: 1px solid grey; */
}
/* .point_history {
  overflow-y: scroll;
} */

.saving_corner_profile {
	background: #fff;
	border-radius: 10px;
	padding: 20px;
}
.reward_points_summary {
	background: #fff;
	border-radius: 10px;
	padding: 20px;
	margin-top: 10px;
}
.point_history_container {
	display: flex;
	justify-content: space-between;
	align-items: end;
	border: 0.1px solid #eee;
	border-radius: 10px;
	margin-bottom: 10px;
	padding: 10px 20px;
	flex-grow: 1; /* Grow to fill available space */
	padding: 10px; /* Example padding */
	overflow-y: auto;
	background-color: #fff;
}
.points_purpose,
.points {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: flex-start;
}

.point_history::-webkit-scrollbar {
	display: none;
}

.coins_info {
	display: flex;
	flex-direction: column !important;
	flex-wrap: wrap;
	align-content: flex-end;
	justify-content: center;
	align-items: center;
}
.profile_coin_info {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.total_earned_points {
	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;
	gap: 10px;
}
@media screen and (max-width: 767px) {
	.point_history_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 0.1px solid #eee;
		border-radius: 10px;
		margin-bottom: 10px;
		padding: 10px 20px;
		flex-grow: 1; /* Grow to fill available space */
		padding: 10px; /* Example padding */
		overflow-y: auto;
		background-color: #fff;
	}
}

.savings_points :focus-visible {
	outline: none;
}
