.ebook_details {
	background-color: #eee;
	display: flex;
	width: 100%;
	height: auto;
}
.ebook_details_container {
	background-color: #fff;
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 5px;
	margin: 30px auto;
}
.order_btn {
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	border-radius: 2px !important;
	width: 350px;
	height: 45px;
}
.place_order {
	text-align: center;
	padding: 10px;
}
.apply_btn {
	text-align: center;
}

.redeem_point_container {
	align-items: center;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}
.redeem_point {
	background-color: #fff !important;
	box-shadow: 0 2px 3px #aaa;
	margin: 5%;
	height: auto;
	width: 650px;
	border-radius: 20px;
	padding-bottom: 10px;
	/* padding-left: 20px;
	padding-right: 20px; */
	/* padding: 15px; */
	/* border-radius: 5px; */
	/* display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: scroll;
  cursor: pointer; */
}
.redeem_heading {
	text-align: center;
	color: #000;
	padding-bottom: 10px;
}
/* .redeem_point{
  top:0px;
  background-color: red;
} */

.add_points {
	/* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); */
	margin: 10px;
	border-radius: 10px;
	padding: 5px;
}
.OfferCoin_Added {
	background: linear-gradient(to right, #093028, #237a57);
	text-transform: none !important;
}
.OfferCoin_Add {
	background: linear-gradient(to right, #141e30, #243b55);
	text-transform: none !important;
}
@media screen and (max-width: 600px) {
	.ebook_details {
		height: auto !important;
	}
	.ebook_details_container {
		width: 100%;
		background-color: #e8e8e8;
		height: auto;
		display: block;
	}
	.cus_details {
		background-color: #fff;
		height: auto;
		/* border-radius: 10px; */
		/* padding: 5px; */
	}
	.redeem,
	.billing_summery {
		background-color: #fff;
		height: auto;
		/* border-radius: 10px; */
		padding: 10px;
	}
	.order_btn {
		background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
		border-radius: 2px !important;
		width: 100%;
		height: 45px;
	}
	.order_car {
		display: none;
	}
	.place_order {
		text-align: center;
		padding: 0px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
	.order_car_img {
		width: 420px !important;
		height: 300px !important;
		margin: auto !important;
	}
	.order_car {
		text-align: center;
	}
}

.redeem_grid.css-1jy0vt-MuiGrid-root,
.css-1gv1zpv-MuiGrid-root {
	justify-content: start !important;
}

.redeem_point :focus-visible {
	outline: none;
}
