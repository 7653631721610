/* .service{
  display: flex;
} */
.service {
  /* border: 1px solid #eee; */
  padding: 30px 0px;
  /* margin-top: 10px;
	background-color: #fff;
	border-radius: 8px; */
}
.service .MuiCardContent-root {
  padding-bottom: 0px !important;
}
.service_img {
  border-radius: 4px;
  width: 50%;
}
.serviceCard {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  box-shadow: 0 0.25rem 0.3125rem -0.15rem rgba(0, 0, 0, 0.12);
  cursor: pointer;
  height: 180px;
  margin: 15px;
  min-width: 230px;
  width: 230px;
}
@media screen and (min-width: 2100px) {
  .css-1iwtu3n-MuiGrid-root {
    max-width: 15% !important;
  }
}

.knowmorebtn.css-155ltdd-MuiButtonBase-root-MuiButton-root {
  padding: 0;
}
