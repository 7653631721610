* {
	margin: 0px;
	box-sizing: border-box;
}
.nav_bar_container {
	/* padding: 10px; */
	background-color: #fff;

	/* box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.2); */
	position: sticky;
}
.nav_bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.nav_bar_img {
	display: flex;
}
.nav_bar_img img {
	width: 120px;
	height: 50px;
	margin-left: 20px;
}
ul.nav_bar_ul {
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
li.nav_bar_li {
	gap: 50px;
	display: flex;
	align-items: center;
	padding-right: 25px;
	color: '#000 !important';
}
li.nav_bar_li a:hover {
	color: orangered;
}
li.nav_bar_li a {
	color: #000;
	text-decoration: none;
	font-weight: 600;
}
.nav_bar_li img {
	width: 30px;
	height: 30px;
}
.active {
	display: block;
}
.icon_shadow {
	cursor: pointer;
	display: flex;
	display: none;
	padding: 3px;
	border-radius: 3px;
	/* box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.2); */
	align-items: center;
	justify-content: center;
}
.mobile_login_btn {
	text-align: center;
	margin-top: 150px;
}
.nav_bar_btn {
	background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
	text-transform: none !important;
	border-radius: 2px !important;
	font-size: 10px !important;
}
.login_icons {
	cursor: pointer;
	/* margin: 0px 20px; */
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	align-items: center;
	/* width: 100%; */
	justify-content: space-between;
}
.header-icons {
	/* width: 15%; */
	text-align: end;
}

@media screen and (max-width: 767px) {
	ul.nav_bar_ul {
		margin-right: 0px;
		display: none;
	}
	.nav_bar_li {
		gap: 30px;
		display: flex;
		padding: 10px;
		font-size: 12px;
	}

	.nav_bar_li a:hover {
		color: orangered;
	}
	.icon_shadow {
		display: flex;
	}
	.nav_bar_ul.active {
		display: flex;
		flex-direction: column;
		position: absolute;
		right: 48px;
		top: 48px;
		z-index: 10;
		box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.2);
		background-color: white;
		padding: 15px;
		border-radius: 4px;
	}
	.nav_bar_ul {
		display: none;
	}
	.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
		background-color: transparent !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 420px) {
	ul.nav_bar_ul {
		margin-right: 0px;
	}
	li.nav_bar_li {
		gap: 15px;
		display: flex;
		align-items: center;
	}
	.nav_bar_img img {
		width: 120px;
		height: 50px;
		margin-left: 0px;
	}
}
@media screen and (min-width: 768px) and (max-width: 1247px) {
	.nav_bar_btn {
		padding: 2px !important ;
		font-size: 10px !important;
	}
}

@media screen and (max-width: 1000px) {
	.icon_shadow {
		display: flex;
	}
}
