.cover_img {
  background-image: url("https://png.pngtree.com/thumb_back/fh260/back_pic/04/46/45/0358573a08ec4a5.jpg");
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}
.cover_edit {
  text-align: end;
}
.cover_edit_icon {
  color: #fff;
  cursor: pointer;
  margin: 20px;
}
.profile_container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 25px;
}
.Profile_details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.profile_page {
  /* max-width: 1000px;
	width: calc(100% - 40px); */
  padding-bottom: 40px;
}
.profile_title,
.profile_other {
  border: 1.5px solid #e1e1e1;
  border-radius: 6px;
  background-color: #fff;
}
/* .profile_other {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
} */
.profile_img {
  width: 100%;
  height: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-position: center;
  object-fit: contain;
}

.profile_name_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.profile_img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  /* padding-top: 4px; */
}
.profile_edit_icon {
  color: white;
  display: flex;
  margin-top: 70px;
  margin-left: -35px;
  cursor: pointer;
}

/* .Verification_container {
} */

.profile_divider .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.profile_divider .css-1q2h7u5.Mui-selected {
  color: orangered !important;
}
.lowercase-tab {
  text-transform: capitalize !important;
}
.profile_divider .css-1aquho2-MuiTabs-indicator,
.profile_divider .css-1x7skt0,
.profile_divider .css-ttwr4n {
  background-color: transparent !important;
}
.verification_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile_divider .MuiTabs-root.css-1ujnqem-MuiTabs-root {
  /* background-color: currentColor; */
  padding: 10px;
}
.profile_divider .css-69z67c-MuiTabs-scroller {
  display: flex !important;
  /* min-width: max-content; */
}

/* .profile_divider
  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  opacity: 0;
  display: none !important;
} */

.profile_divider .css-heg063-MuiTabs-flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile_divider
  .MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root,
.profile_divider
  .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile.css-1q0ig8p {
  display: none;
}

.profile_divider
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3.profile_title.css-1equabv-MuiGrid-root {
  height: 100%;
}
.profile_divider .css-1o3jkkl-MuiButtonBase-root-MuiTab-root,
.profile_divider
  button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.lowercase-tab.css-13887fw,
.profile_divider
  .button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.lowercase-tab.active-tab.css-13887fw {
  min-width: max-content !important;
}

.profile_modal:focus-visible,
.profile_modal {
  border: none !important;
  outline: none !important;
}
/* .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  z-index: -1 !important;

  opacity: 0.1;
} */

.profile_name,
.profile_member {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.MuiPopper-root.MuiPickersPopper-root.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 99999999 !important;
}
.active-tab {
  color: white !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

@media screen and (max-width: 1000px) {
  .profile_divider {
    flex-direction: column !important;
    align-items: center;
    /* gap: 20px; */
  }
  .profile_title,
  .profile_other {
    /* max-width: 100% !important; */
    width: 100%;
    justify-content: normal;
  }
  /* header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    width: 78%;
  } */
  .profile_tab {
    width: 100%;
    overflow-x: scroll;
  }
}

.scrollable-tabs {
  overflow-x: scroll;
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .profile_bgimg {
    width: 100%;
    height: 190px;
  }
}

@media only screen and (min-width: 600px) {
  .profile_bgimg {
    width: 100%;
    height: 250px;
  }
}

@media only screen and (min-width: 768px) {
  .profile_bgimg {
    width: 100%;
    height: 360px;
  }
}

@media only screen and (min-width: 1200px) {
  .profile_bgimg {
    width: 90%;
    height: 420px;
  }
}

/* @media only screen and (min-width: 768px) {
	.profile_bgimg {
		width: 100%;
		height: 400px;
	}
} */

@media (min-width: 300px) {
  .css-kq36xo-MuiGrid-root::-webkit-scrollbar {
    display: none;
  }
}

.profileupdatepp:focus-visible {
  outline: none !important;
}
