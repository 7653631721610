.buyForm {
	margin: 20px;
}

.Form_Models {
	width: 100%;
	overflow-x: scroll;
	scroll-behavior: smooth;
	/* display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center; */
}
.Form_Models::-webkit-scrollbar,
.css-19i4lca-MuiGrid-root::-webkit-scrollbar,
.css-1b6bv3h-MuiGrid-root::-webkit-scrollbar {
	display: none !important;
}
.contact_modal {
	align-items: center;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}
.contact_details {
	background-color: #fff !important;
	border-radius: 24px;
	box-shadow: 0 2px 3px #aaa;
	margin: 5%;
	width: 550px;
	padding: 15px;
}

.Form_Brands {
	display: flex;
	justify-self: center;
	align-items: start;
	flex-direction: row;
	column-gap: 16px;
	width: 1500px;
	margin: 10px;
}

@media screen and (max-width: 767px) {
	.Form_Brands {
		width: 1500px !important;
		justify-content: flex-start !important;
		align-items: center !important;
	}
}

.fuelcard.css-46bh2p-MuiCardContent-root:last-child {
	padding-bottom: 16px;
}
/* 
.transmissioncard.css-46bh2p-MuiCardContent-root:last-child {
	padding-bottom: 16px;
} */

.choosen_brand {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 120px;
	height: 100px;
}

.choosen_model {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 120px;
	height: 65px;
}
