.choosencar_card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.wishlist_img_container {
  width: 50%;
  height: 50%;
}
.wishlist_serviceimg_container {
  width: 150px;
  height: 150px;
}
.emptycart_img_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 250px;
}
.emptycart_img {
  width: 100%;
  height: 100%;
}
.wishLists
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-mvadxi-MuiPaper-root {
  padding: 0px;
}
.wishlistBtnActive {
  background: linear-gradient(to right, #ff512f, #f09819);
}
.wishlistBtn {
  background: linear-gradient(to right, #141e30, #243b55);
}

.wishlistcar_card {
  padding: 0px !important;
  /* box-shadow: 9px 9px 9px #e6e6e6e6; */
  /* border: '0.1px solid #eee'; */
  border: 0.1px solid #eee;
  margin-bottom: 26px !important;
}

.swipe-to-delete .js-delete {
  background: linear-gradient(to right, #f00000, #dc281e) !important;
}

@media screen and (max-width: 767px) {
  .wishlist_img_container {
    width: 140px;
    height: 100px;
  }
}

.wishlistcar_card {
  padding: 0px;
  /* border-radius: 4px; */
}

.cart_maincard.css-1q4n0u6-MuiPaper-root {
  padding: 0;
}

.btnEbook.MuiButton-root {
  border-radius: 0px;
}

.btnRemove.MuiButton-root {
  border-radius: 0px;
}

.servicecardcart.css-1a7659b-MuiPaper-root {
  padding: 0px;
}

.btnRemoveservice.MuiButton-root {
  border-radius: 0px;
}

.btnEbookservice.MuiButton-root {
  border-radius: 0px;
}
