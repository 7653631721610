.category_main {
	width: 85%;
	height: 70px;
	background-color: white;
	display: flex;
	justify-content: flex-start;
	margin: auto;
	align-items: center;
}
.category_main .category_item {
	flex: 1 1 100px;
	max-width: 120px;
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: center;
	padding: 10px;
	border-radius: 2px;
	cursor: pointer;
}
.category_main .category_item p {
	font-weight: 600;
	font-size: 14px;
}
.active:after,
.active_navtitle:after {
	width: 100%;
	height: 4px;
	content: '';
	background: #eb232a;
	border-radius: 4px 4px 0 0;
	position: absolute;
	bottom: -10px;
	left: 0;
	z-index: 9;
}

@media screen and (max-width: 1000px) {
	.category_item {
		/* flex: 1 1 50px; */
		/* max-width: 100px; */
		display: flex;
		justify-content: space-around;
		align-items: center;
		align-content: center;
		border-radius: 2px;
		cursor: pointer;
	}
	.category_main .category_item p {
		font-size: 12px;
		font-weight: 600;
	}
	.category_main {
		width: 100%;
		height: 55px;
		background-color: #e7e9eb;
		display: flex;
		justify-content: space-evenly;
		align-content: center;
		align-items: center;
	}
}
