.address_modal {
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  align-content: center;
}
.add_address {
  background-color: #fff !important;
  border-radius: 2px;
  box-shadow: 0 2px 3px #aaa;
  margin: 5%;
  width: 550px;
  height: 400px;
  /* padding: 15px; */
  border-radius: 5px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.add_address::-webkit-scrollbar {
  display: none;
}

.modal_address {
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  text-transform: none !important;
  border-radius: 2px !important;
  width: 150px;
}
.modal_btn {
  background: linear-gradient(to right, #b79891, #94716b) !important;
  border-radius: 2px !important;
}
.modal_address_btn {
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  text-transform: none !important;
  border-radius: 2px !important;
  width: 90px;
  text-align: end;
}
