.ebookService {
  background-color: #eee;
  display: flex;
  width: 100%;
  height: 100vh;
}

.ServiceEbookContainer {
  background-color: #fff;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
}
.personal_input.css-isbt42 {
  justify-content: center;
}
.personal_input .css-isbt42 {
  justify-content: center;
}
@media screen and (max-width: 1500px) {
  .ServiceEbookContainer {
    width: 100%;
  }
}

/* @media screen and (max-width: 1500px) {
	.ebookService {
		background-color: #eee;
		display: flex;
		width: 100%;
		height: 100%;
	}
} */

.tab_container_service {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otherdetails_service {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 400px;
}
.otherdetails_service::-webkit-scrollbar {
  display: none;
}

/* .ebook {
	background-color: #eee;
	display: flex;
	width: 100%;
	height: 100vh;
}
.ebookContainer {
	background-color: #fff;
	width: 90%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 5px;
} */

/* .tab_container {
	display: flex;
	justify-content: center;
	align-items: center;
} */
.ebook_submit_service {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  border-radius: 2px !important;
  width: 250px;
}
.ebook_address_btn_service {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  border-radius: 2px !important;
}
.ebook_btn_service {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .ebook_btn > .ebook_tab{
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  border-radius: 2px !important;
  width: 350px;
} */
.css-mhc70k-MuiGrid-root {
  justify-content: center;
  align-items: center;
}
.numberCount_service {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  width: 35px;
  height: 35px;
  margin: auto;
  text-align: center;
}
.disclaimer_service {
  width: 80%;
  margin: auto;
}
.css-85h2bp-MuiButtonBase-root-MuiButton-root {
  background-color: gray !important;
}
.paymentlist {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  margin: 20px 40px;
}
.payment_btn {
  /* background-image: linear-gradient(to right, #f83600 0%, #f9d423 100%); */
  /* background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%); */
  /* background-image: linear-gradient(45deg, #874da2 0%, #c43a30 100%); */
  /* background: linear-gradient(to right, #f12711, #f5af19) */
  background: linear-gradient(to right, #ff416c, #ff4b2b);
}
.price_btn {
  background-color: #708090 !important;
}
.otherdetails_container_service,
.Others_container_personal_Details {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: 400px;
}
.otherdetails_container_service::-webkit-scrollbar,
.Others_container_personal_Details::-webkit-scrollbar {
  display: none;
}
.css-k4qjio-MuiFormHelperText-root.Mui-error,
.css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  text-align: end;
  margin: 0px;
}

.addressList_modal_service {
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.addressList_service {
  background-color: #fff !important;
  box-shadow: 0 2px 3px #aaa;
  margin: 5%;
  width: 550px;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  cursor: pointer;
  justify-items: center;
}

.list_service.css-1p823my-MuiListItem-root {
  justify-content: center;
}

.addressList_service::-webkit-scrollbar {
  display: none;
}

/* .modal_address{
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  text-transform: none !important;
  border-radius: 2px !important;
  width: 150px;
}
.modal_btn{
  background:#ff416c !important;
  border-radius: 2px !important;
  width: 90px;
}
.modal_address_btn{
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
  text-transform: none !important;
  border-radius: 2px !important;
  width: 90px;
  text-align: end;
} */

.list_service {
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  /* background: linear-gradient(to right, #8360c3, #2ebf91); 
  /* background: linear-gradient(to right, #c31432, #240b36); */
  /* background: linear-gradient(to right, #8a2387, #e94057, #f27121);  */
  /* background: linear-gradient(to right, #636363, #a2ab58); */
  text-transform: none !important;
  border-radius: 5px !important;
  width: 420px;
  padding: 15px;
  color: #fff;
  text-align: start;
}

@media screen and (max-width: 500px) {
  .list_service {
    width: 300px;
  }
}
