.slider_main {
  /* background-color: white; */
  padding: 10px;
  width: "100%";
  border: 1px solid #eee;
  padding: 30px 0px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.card_img {
  width: 100%;
  height: auto;
}
.card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card_content .title {
  font-size: 15px;
  font-weight: 700;
}
.card_content h3 {
  color: green;
  font-weight: 500;
  padding-top: 6px;
  padding-left: 6px;
}
.card_content .subtitle {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  margin-top: 4px;
}
.slider_card_main {
  padding: 0px 10px;
}
.slider_card {
  background-color: white;
  padding: 10px;
  border-radius: 12px;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
