.NotYetSearched {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.search_img_container {
	width: 50%;
	height: 50%;
}
.search_card {
	padding: 0px !important;
	/* box-shadow: 9px 9px 9px #e6e6e6e6; */
	/* border: '0.1px solid #eee'; */
	border: 0.1px solid #eee;
	border-radius: 5px;
	margin-bottom: 26px !important;
}

.cardcar_img {
	width: 90px;
	height: 60px;
}

@media only screen and (min-width: 768px) {
	.cardcar_img {
		width: 120px;
		height: 80px;
	}
}
