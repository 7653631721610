.Enquery_image {
  background-position: 100%;
  background-position-x: center;
  background-repeat: no-repeat;
  align-items: center;
  background-color: #cbcbcb;
  background-size: cover;
  display: flex;
  width: 100%;
}
.Enquery_main {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.Enquery_form {
  padding: 20px;
  max-width: 350px;
  border-radius: 10px;
  display: flex;
  height: auto;
  flex-direction: column;
  margin: 5% 5% 5% 150px;
  background-color: rgba(255, 255, 255, 0.8);
}
.css-xmq9qu-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 5px !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  margin-bottom: 10px;
}
.leftForm {
  width: 800px;
}

@media (max-width: 700px) {
  .Enquery_image {
    padding: 0px;
  }
  .Enquery_form {
    margin: 5%;
  }
  .leftForm {
    width: 150px;
  }
}
