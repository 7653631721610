/* .banner_img{
  width: 100% !important;
} */
.banner_slider {
  height: 35vw;
}
.banner {
  position: absolute;
  width: calc(100vw - 8px);
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px !important;
  line-height: 1;
  opacity: 0.75;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 767px) {
  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    height: 20px !important;
    width: 20px !important;
    box-shadow: unset;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 12px !important;
    line-height: 1;
    opacity: 0.75;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    height: 30px !important;
    width: 30px !important;
    box-shadow: unset;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 14px !important;
    line-height: 1;
    opacity: 0.75;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media screen and (min-width: 2000px) {
  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    height: 70px !important;
    width: 70px !important;
    box-shadow: unset;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 35px !important;
    line-height: 1;
    opacity: 0.75;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
