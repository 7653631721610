.list_body {
  padding: 15px;
  color: white;
}
.list_body .list_heading {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
}
.list_body ul {
  padding: 0px !important;
}
.footer_listitem {
  list-style-type: none !important;
}
.list_body ul li {
  padding: 6px 0px;
}
.list_body ul li a {
  color: white !important;
  font-size: 12px;
  text-decoration: none;
}
.list_body ul li:hover {
  color: yellow !important;
  cursor: pointer;
}
.list_body ul li a:hover {
  color: yellow !important;
  cursor: pointer;
}
/* .social_media_container {
	padding: 10px;
} */
.social_media_container p {
  font-size: 14px;
  color: white;
  font-weight: 500;
  padding: 6px 0px;
}
.social_media_container .container {
  display: flex;
  flex-direction: row;
}
.social_media_container .container .item {
  padding: 10px;
  color: white;
}
.social_media_container .container .item:hover {
  color: yellow;
  cursor: pointer;
}

/* hover for small screens */
.item:hover {
  color: #1616ff;
  cursor: pointer;
}
.support_main {
  padding: 0px 10px;
}
.support_main {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.support_mail,
.support_phone {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
  justify-content: center;
}

.mail,
.phone {
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
}
.mail_icon,
.phone_icon {
  font-size: 16px;
}
.blr_mail,
.blr_phone {
  font-size: 14px;
  font-weight: 500;
}

.support_mail div p {
  margin-left: 8px;
}
.footer_applink {
  padding: 10px;
}

@media screen and (min-width: 1536px) {
  .footer_applink {
    align-items: end;
    margin-right: 60px;
  }
}

@media screen and (max-width: 550px) {
  .support_main {
    gap: 10px;
  }
  .css-174k9uk-MuiGrid-root,
  .css-henfzj-MuiGrid-root {
    padding: 5px !important;
  }
  .support_mail,
  .support_phone {
    justify-content: start;
  }
  .blr_mail,
  .blr_phone {
    width: 150px;
    overflow-x: scroll;
  }
  .blr_mail::-webkit-scrollbar {
    display: none;
  }
}
