.signup_modal {
  background-color: white;
  border-radius: 2px;
  /* box-shadow: 0 2px 3px #aaa; */
  margin: 5%;
  width: 1000px;
  border-radius: 25px;
  height: 528px;
}
.signup_container {
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}
.signup_content {
  /* background: linear-gradient(to right, #aa4b6b, #6b6b83, #3b8d99); */
  /* background: linear-gradient(to bottom, #136daa 65%, #ffffff 100%); */
  background: linear-gradient(to bottom, #37414b 0%, #92a1b3 100%);
  /* background: linear-gradient(to bottom, #0c4c93 0%, #436175 100%); */
  /* background: #136daa; */
  height: 528px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signup_form {
  padding: 0px 20px 20px 20px;
}
.existing_user {
  border: none !important;
  line-height: 180%;
  text-align: center;
  color: #2874f0;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px !important;
}
/* .continue_button {
	font-weight: 600;
	font-size: 16px;
	text-transform: none;
	width: 60%;
	height: 50px;
	background-color: #ef6e0b;
	border-radius: 16px;
	color: white;
} */
.new_user_btn {
  bottom: -85px;
  position: relative;
}

.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signin_img {
  width: 100%;
  height: 100%;
  position: relative;
  /* bottom: -50px; */
}
.signin_img_container {
  width: 50%;
  height: 50%;
  margin-top: 20px;
}
.custom-input {
  margin: 4px;
  width: 30px;
  min-width: 45px;
  min-height: 45px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 800px) {
  .custom-input {
    min-width: 32px;
  }
}

.countdown-text {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 10px;
}
/* @media screen and (max-width: 600px) {
	.signup_form {
		margin-top: 50px !important;
	}
} */
