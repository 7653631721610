@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body,
.MuiTypography-root,
.MuiFormLabel-root,
.MuiButtonBase-root,
.MuiInputBase-input {
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
* {
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-i5893t-MuiCardActions-root > :not(:first-of-type) {
  margin-left: 0px !important;
}
