.stepperSubDiv {
	/* margin: 1.4em; */
	background: white;
	border-radius: 12px;
	color: #63b38f;
	width: 95%;
	margin-bottom: 1.4em;
	margin-top: 1.4em;
}

@media only screen and (min-width: 992px) {
	.stepperSubDiv {
		width: 85%;
	}
}

/* .stepper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
} */

/* .stepperSubDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.formContainer{
  min-width: 1200px;
  margin-top: 20px;
} */

.stepperButtons_next {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.stepperButtons {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	column-gap: 20px;
}
.stepBtn {
	background-image: linear-gradient(to top, #09203f 0%, #537895 100%);
	text-transform: none !important;
	border-radius: 2px !important;
}

.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.css-10mg1vw-MuiStepper-root {
	margin: 40px 0px;
}
.evaluation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.p-calendar {
	font-size: 14px;
	width: 200px;
}

.p-calendar-w-btn-right .p-inputtext {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	padding: 10px 20px;
}

.p-calendar .p-inputtext {
	width: 80% !important;
	display: flex !important;
	flex: none !important;
	font-size: 14px;
	font-weight: 600;
}
.custom-calendar {
	width: 200px !important;
}
.p-datepicker table th,
.p-datepicker table td {
	padding: 0.1rem !important;
	font-size: 11px;
}
.p-datepicker table td > span {
	width: 2rem !important;
	height: 2rem !important;
}
.p-datepicker.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
	width: 270px !important;
	/* display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important; */
	/* top:35% !important; */
	/* left:50% !important; */
}
.p-datepicker .p-datepicker-header {
	padding: 0px !important;
}
.p-icon {
	width: 0.7rem !important;
	height: 0.7rem !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
	font-size: 13px !important;
}
@media screen and (max-width: 767px) {
	.p-datepicker.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
		width: 270px !important;
		top: 35% !important;
		left: 15% !important;
	}
}
