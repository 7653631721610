.Details {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Details_Container {
  max-width: 1000px;
  width: calc(100% - 40px);
  padding-bottom: 40px;
}
.title_bar {
  background-color: #2f378e;
  border-radius: 1.5px;
  height: 3px;
  margin: 1rem 0rem !important;
  width: 120px;
}
.details_nav {
  background-color: #fff;
  box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.103);
  color: #474747;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  padding: 0 2%;

  cursor: pointer;
}
.details_image {
  background-color: wheat;
  border-top-left-radius: 6px;
  height: 300px;
  width: 450px;
  cursor: pointer;
}
.specs_value {
  display: flex;
  flex-wrap: wrap;
  margin: 6px 0;
  overflow: auto;
  width: 100%;
}
.specs {
  background-color: #fbfbfb;
  border: 1px solid #efefef;
  padding: 10px;
  width: calc(50% - 0px);
}
.about,
.description {
  background-color: #fbfbfb;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 10px 20px;
}
.details_nav::-webkit-scrollbar {
  display: none;
}
.car_details {
  border: 1.5px solid #e1e1e1;
  border-radius: 6px;
  display: flex;
  width: 100%;
}
.car_version {
  border-bottom: 1.5px solid #e1e1e1;
  border-left: 1.5px solid #e1e1e1;
  max-width: 250px;
  padding-top: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  width: 100%;
}
.car_color {
  border-bottom: 1.5px solid #e1e1e1;
  border-left: 1.5px solid #e1e1e1;
  max-width: 250px;
  padding-top: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  width: 100%;
}
.car_details_colors {
  align-items: center;
  border: 1.5px solid #e1e1e1;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  padding: 14px;
}
.color_variant {
  border: 2px solid #fff;
  font-size: 13px;
  height: 20px;
  margin: 0 10px;
  min-width: 20px;
  width: 20px;
}
div#demo-simple-select {
  padding: 5px 15px;
}
.Over_view_container .MuiGrid-item {
  padding-top: 0px !important;
}
.modal,
.verify_modal_container {
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  flex-direction: column;
}
.emi_calculator,
.verify_modal {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 2px 3px #aaa;
  margin: 5%;
  max-width: 550px;
  padding: 15px;
}
.variant-color {
  align-items: center;
  width: 150px !important;
  justify-content: center;
  height: 120px;
  flex-direction: column;
  gap: 1;
}
:is(.car_variant_colors, .related-scroll)::-webkit-scrollbar {
  display: none;
}
.related-img img {
  width: 200px;
}

.related-img_related img {
  height: 100px;
  width: 140px;
}

.related-scroll {
  overflow-x: scroll !important ;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  cursor: pointer;
}
.realted-card {
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 15px;
  width: 100%;
  height: 180px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.onroad_price {
  background-color: #fff !important;
  border-radius: 20px;
  box-shadow: 0 2px 3px #aaa;
  margin: 5%;
  width: 350px;
  padding: 15px;
}

.wishlist,
.book,
.price,
.modal_onroad,
.close {
  /* background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%); */
  /* background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%); */
  /* background-image: linear-gradient(to top, #09203f 0%, #537895 100%); */
  /* background: linear-gradient(to right, #ed213a, #93291e); */
  background: linear-gradient(to right, #e44d26, #f16529);
  text-transform: none !important;
  border-radius: 2px !important;
}

@media screen and (max-width: 600px) {
  .details_image {
    width: 100%;
    height: auto;
  }
}

.date_picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
}

.panverify_modal:focus-visible {
  outline: none !important;
}
