.MuiGrid-root.MuiGrid-grid-xs-1.paymentLineBar.css-szb5eh-MuiGrid-root,
.MuiGrid-root.MuiGrid-grid-xs-1.paymentLineBar.css-12zpuzd {
  max-width: 1% !important;
}
/* .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3.paymentRequestStatus.css-1equabv-MuiGrid-root,
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-3.paymentRequestStatus.css-4xkoi8 {
  max-width: 9% !important;
} */
.payment_request_screen {
  margin: 30px;
}
.payment_request_img_grid {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .payment_req_img_container {
  width: 250px;
  height: 250px;
} */
.payment_Request_contianer {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.payment_request_name_container,
.payment_card_container {
  border: 0.1px solid #eee;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}
.payment_card{
  cursor: pointer;
}
.payment_request_name_container {
  padding: 20px;
}
.payment_card_mobile_grid {
  width: 50%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
}
.Mobile_grid_payment {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: end;
}
.payment_card_grid {
  justify-content: space-between;
  display: flex;
  align-items: center;
  /* padding-left: 50px; */
}
.payment_request_name_grid {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}
.request_container {
  overflow-y: scroll;
  height: 100vh;
}
.request_container::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 767px) {
  .MuiGrid-root.MuiGrid-grid-xs-1.paymentLineBar.css-szb5eh-MuiGrid-root,
  .MuiGrid-root.MuiGrid-grid-xs-1.paymentLineBar.css-12zpuzd {
    max-width: 1% !important;
  }
  .payment_req_img_container {
    width: 150px;
    height: 130px;
  }
}

.payment_card.css-1egumzf {
  text-align: start;
}
.payment_request_screen {
  margin: 10px;
}
