.pan_img {
  width: 120px;
  height: 120px;
  /* border-radius: 50%;
  background: linear-gradient(to right, #636363, #a2ab58); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.verified_img {
  width: auto;
  height: 300px;
}
.profile_verified {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pan_img img {
  width: 300px;
  height: 200px;
  /* border-radius: 50%; */
  object-position: center;
  object-fit: contain;
}
.pan_img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  padding-top: 4px;
}
.pan_edit_icon {
  color: black;
  display: flex;
  margin-top: 120px;
  margin-left: -5px;
  cursor: pointer;
}
