.yourOrdersBtnActive {
  background: linear-gradient(to right, #f12711, #f5af19);
}
.yourOrdersBtn {
  background: linear-gradient(to right, #141e30, #243b55);
}

.emptyorders_img_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 260px;
}

.emptyorders_img {
  width: 100%;
  height: 100%;
}

.orderscar_card {
  padding: 0px;
  border-radius: 4px;
}

.orders_img_container {
  width: 40%;
  height: 40%;
}
@media screen and (max-width: 767px) {
  .orders_img_container {
    width: 140px;
    height: 100px;
  }
}

.orders_serviceimg_container {
  width: 150px;
  height: 150px;
}
